import React, { useEffect } from "react"

import BlogPostTemplate from "../Templates/blogPostTemplate"
import { ContentWrapper } from "../containers/alpacked.style"
import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import homeTemplate from "./index"
import { navigate } from "gatsby"
import { withUnpublishedPreview } from "gatsby-source-prismic"

const CaseStudy = ({ location }) => {
  useEffect(() => {
    navigate(
      `/begin-your-reliable-way-to-pass-a-compliance?utm_source=email&utm_campaign=qZUOebF0xop+4QVn6jsv/BCw1mkykwMjDpNOPqal1jem5m3JlDSnagOj+4lwXqESBseWvJl4m3oqhQoWSZuQ/g==`
    )
  }, [])

  return (
    <>
      <SEO
        location={location}
        title="Alpacked DevOps Company"
        shortDesc="Begin your reliable way to pass a compliance"
        description="Begin your reliable way to pass a compliance"
      />
      <Layout>
        <ContentWrapper>
          <iframe
            src="/begin-your-reliable-way-to-pass-a-compliance.pdf"
            width="100%"
            height="1000"
            title="Begin your reliable way to pass a compliance"
            style={{ marginTop: "74px" }}
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default withUnpublishedPreview(CaseStudy, {
  templateMap: {
    page: BlogPostTemplate,
    homepage: homeTemplate,
    prismicPage: BlogPostTemplate,
    prismicHomepage: homeTemplate,
  },
})
